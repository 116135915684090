<template>
	<swiper-container init="false" ref="swiper">
		<slot></slot>
	</swiper-container>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { register } from "swiper/element/bundle";

register();

const swiper = ref(null);
const swiperParams = {
	slidesPerView: 1,
	autoplay: {
		delay: 8000,
		pauseOnMouseEnter: true,
	},
	speed: 800,
	grabCursor: true,
	loop: true,
	spaceBetween: 50,
};

onMounted(() => {
	Object.assign(swiper.value, swiperParams);
	swiper.value.initialize();
});
</script>

<style scoped></style>
