<template>
	<section ref="hero">
		<slot></slot>
	</section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const hero = ref(null);

function setHeightUnitVar() {
	const heightUnit = hero.value.offsetHeight / 100;
	hero.value.style.setProperty("--height-unit", `${heightUnit}px`);
}

function resizeHandler() {
	setHeightUnitVar();
}

onMounted(() => {
	window.addEventListener("resize", resizeHandler, {
		passive: true,
	});
	resizeHandler();
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", resizeHandler);
});
</script>

<style scoped></style>
