<template>
	<header ref="header" class="transition-all duration-500">
		<slot :open-menu="openMenu"></slot>
	</header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { store } from "@js/store.js";

function openMenu() {
	store.openMenu();
}

const header = ref(null);

let trigger = null;
let heroShadow = null;
let prevScrollPos = window.scrollY;

const handleScroll = () => {
	if (trigger == null) {
		return;
	}

	const headerHeight = header.value.getBoundingClientRect().height;
	const triggerTop = trigger.getBoundingClientRect().top;

	if (triggerTop <= headerHeight + 100) {
		header.value.classList.add("header-scrolled");
		heroShadow.classList.add("hero-shadow-hide");
		if (window.scrollY > prevScrollPos) {
			header.value.classList.add("header-hidden");
		} else {
			header.value.classList.remove("header-hidden");
		}
		prevScrollPos = window.scrollY;
	} else {
		header.value.classList.remove("header-scrolled");
		heroShadow.classList.remove("hero-shadow-hide");
	}
};

onMounted(() => {
	window.addEventListener("scroll", handleScroll);
	window.addEventListener("resize", handleScroll);
	document.addEventListener("readystatechange", (event) => {
		if (document.readyState === "complete") {
			trigger = document.querySelector("[data-header-trigger]");
			heroShadow = document.querySelector("[data-hero-shadow]");
			handleScroll();
		}
	});
});

onBeforeUnmount(() => {
	window.removeEventListener("scroll", handleScroll);
	window.removeEventListener("resize", handleScroll);
});
</script>

<style scoped>
.header-scrolled {
	@apply bg-black/75 backdrop-blur-lg;
}
.header-hidden {
	@apply transform translate-y-[-100%];
}
</style>
