import { lock, unlock } from "tua-body-scroll-lock";

export const scrollLock = function () {
	const el = document.querySelector("#mobile-menu");
	lock(el), { overflowType: "clip" };
};
export const scrollUnlock = function () {
	const el = document.querySelector("#mobile-menu");
	unlock(el);
};
