<template>
	<section
		class="reviews relative flex flex-col bg-white max-w-2000 mx-auto"
		ref="reviews"
	>
		<div class="reviews-pattern"></div>
		<div class="reviews-content relative pt-[var(--slant-width)]">
			<slot></slot>
		</div>
	</section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const reviews = ref(null);

function setHeightUnitVar() {
	const heightUnit = reviews.value.offsetHeight / 100;
	reviews.value.style.setProperty("--height-unit", `${heightUnit}px`);
}

function resizeHandler() {
	setHeightUnitVar();
}

onMounted(() => {
	resizeHandler();
	window.addEventListener("resize", resizeHandler, {
		passive: true,
	});
	document.addEventListener("readystatechange", (event) => {
		if (document.readyState === "complete") {
			resizeHandler();
		}
	});
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", resizeHandler);
});
</script>

<style scoped>
.reviews {
	--height-unit: 1vh;

	--slant-width-base: 10;
	--slant-width: calc(var(--slant-width-base) * min(1vw, 2000px / 100));
	--half-slant-width: calc(var(--slant-width) / 2);
}

.reviews-content {
	@apply z-10 flex-shrink-0;

	&:after {
		content: "";
		@apply absolute inset-x-0 bottom-0 -top-1 -z-10;

		clip-path: polygon(0 0, 100% 0, 100% 1px, 0% var(--slant-width));

		@apply bg-black;
	}
}

.reviews-pattern {
	@apply absolute overflow-hidden z-20;

	@apply w-full inset-x-0;

	height: calc(var(--slant-width) + 50px);

	/* background-color: red;
	opacity: 0.5; */

	&:after {
		content: "";
		@apply absolute inset-y-0;
		background-image: url("/assets/graphics/track-sideways.svg");
		background-repeat: repeat-x;
		left: -250px;
		right: -250px;
		transform: rotate(atan2(calc(var(--slant-width-base) * -1), 100));

		@screen to-sm {
			background-size: auto 50px;
			background-position: bottom calc(var(--half-slant-width) + 15px)
				center;
		}

		@screen sm {
			background-size: auto 65px;
			background-position: bottom calc(var(--half-slant-width) + 5px)
				center;
		}
	}
}
</style>
