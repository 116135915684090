import { reactive } from "vue";
import { scrollLock, scrollUnlock } from "./composables/scroll-lock";

export const store = reactive({
	menuOpen: false,
	openMenu() {
		this.menuOpen = true;
		scrollLock();
	},
	closeMenu() {
		this.menuOpen = false;
		scrollUnlock();
	},
});
